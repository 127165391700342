<template>
  <div>
    {{ getToDate() }}
  </div>
</template>
<script>
export default {
  props: ['toDate'],
  methods: {
    getToDate() {
      if (this.toDate === null || "undefined" === typeof this.toDate) {
        return this.$t('components.custom.playground.new.step-2.permanent');
      }
      return this.$moment(this.toDate).format('YYYY-MM-DD')
    }
  }
}
</script>
<style scoped>

</style>